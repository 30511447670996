:root,
body,
html,
#root {
  margin: 0;
  height: 100%;
  background-color: var(--blue-1);
}

@keyframes bumpIn {
  0% {
    opacity: 0%;
    transform: translateY(20%);
  }
  100% {
    opacity: 100%;
    transform: translateY(0);
  }
}
